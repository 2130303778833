/**
 * Product Gallery Zoom Carousel
 * @param {object} opts
 * @returns {object} Flickity instance
 */

import Flickity from 'flickity';

function productGalleryZoomCarousel(opts) {
  const config = {
    dom: {
      carousel: '.js-product-gallery-zoom-carousel',
      videoBtn: '.js-video-control',
      carousel_cell: '.js-product-gallery-zoom-carousel-cell',
    },
    flickityOpts: {
      prevNextButtons: true,
      pageDots: true,
      arrowShape: '',
      wrapAround: true,
      initialIndex: '.is-initial-select',
      setGallerySize: false,
    },
    arrangeImages: true,
  };

  const c = __.extend(config, opts);
  const $carousel = document.querySelector(`${c.dom.carousel}`);

  /**
   * Init
   */
  function init() {
    if (!$carousel) {
      return null;
    }
    const isEnabled = __.hasClass($carousel, 'flickity-enabled');

    if (isEnabled) return null;

    if (!c.arrangeImages) {
      return makeCarousel($carousel);
    }

    return arrangeImages($carousel);
  }

  function arrangeImages(carousel) {
    const $productImages = [...carousel.querySelectorAll(c.dom.carousel_cell)];
    const $productImagesSorted = $productImages.sort((a, b) => {
      const aPriority = a.dataset.imagePriority;
      const bPriority = b.dataset.imagePriority;
      return aPriority - bPriority;
    });

    if ($productImagesSorted?.length > 0) {
      carousel.innerHTML = '';
      [...$productImagesSorted].forEach(($productImage, idx) => {
        $productImage.setAttribute('data-cell-index', idx);

        carousel.appendChild($productImage);
      });

      makeCarousel(carousel);
    }
  }

  function makeCarousel(carousel) {
    const flickityCarousel = new Flickity(carousel, c.flickityOpts);

    flickityCarousel.on('ready', () => {
      // make sure the images load the correct size when the carousel is ready
      if (window?.lazySizes?.autoSizer?.checkElems) {
        window.lazySizes.autoSizer.checkElems();
      }
    });
    flickityCarousel.on('settle', () => {
      // make sure the images load the correct size when the carousel settles
      if (window?.lazySizes?.autoSizer?.checkElems) {
        window.lazySizes.autoSizer.checkElems();
      }
    });
    flickityCarousel.on('change', (index) => {
      const $videoBtns = [...$carousel.querySelectorAll(c.dom.videoBtn)];
      if ($videoBtns && $videoBtns.length > 0) {
        $videoBtns.forEach(($videoBtn) => {
          $videoBtn.setAttribute('tabindex', -1);
        });
      }
      const $selectedVideoBtns = [...$carousel.querySelectorAll(`[data-cell-index="${index}"] ${c.dom.videoBtn}`)];
      if ($selectedVideoBtns && $selectedVideoBtns.length > 0) {
        $selectedVideoBtns.forEach(($selectedVideoBtn) => {
          $selectedVideoBtn.setAttribute('tabindex', 0);
        });
      }
      // make sure the images load the correct size when the carousel changes
      if (window?.lazySizes?.autoSizer?.checkElems) {
        window.lazySizes.autoSizer.checkElems();
      }
    });
    return flickityCarousel;
  }

  return init();
}

export default productGalleryZoomCarousel;
