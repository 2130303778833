/**
 * Elevar ATC
 * @param {object} data
 * @param {object} opts
 */
function elevarTracking(data, eventType) {
  // global variables
  const products = [
    {
      id: data.sku, // SKU
      name: data.product_title, // Product title
      brand: data.vendor,
      category: data.product_type,
      variant: data.variant_title,
      price: `${data.price}`,
      quantity: `${data.quantity}`, // Not required for dl_select_item & dl_view_item
      list: data.product_type, // The list the product was discovered from
      product_id: `${data.product_id}`, // The product_id
      variant_id: `${data.variant_id}`, // id or variant_id
      compare_at_price: `${data.compare_at_price || '0.0'}`, // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
      image: data.image || '', // If available, otherwise use an empty string
      inventory: `${data.inventory_quantity || ''}`, // If available, only required on dl_view_item
    },
  ];

  const { customer } = window.SDG.Data.elevarUserData;
  const userProperties = customer?.id ? {
    customer_email: customer?.email,
    customer_first_name: customer?.firstName,
    customer_last_name: customer?.lastName,
    customer_id: `${customer?.id}`,
    customer_tags: customer?.tags,
    customer_zip: customer?.zip,
    customer_city: customer?.city,
    customer_province: customer?.province,
    customer_province_code: customer?.provinceCode,
    customer_address_1: customer?.address1,
    customer_address_2: customer?.address2,
    customer_country: customer?.country,
    customer_country_code: customer?.countryCode,
    customer_phone: customer?.phone,
    visitor_type: 'logged_in',
  } : {
    visitor_type: 'guest',
  };

  /**
   * Init
   */
  function init() {
    if (eventType === 'add') {
      handleAddEvent();
    } else if (eventType === 'remove') {
      handleRemoveEvent();
    }
  }

  /**
   * Handle Add Event
   */
  function handleAddEvent() {
    window.ElevarPushToDataLayer({
      event: 'dl_add_to_cart',
      user_properties: userProperties,
      ecommerce: {
        currencyCode: 'USD',
        add: {
          actionField: {
            list: window.location.pathname,
          },
          products,
        },
      },
    });
  }

  /**
   * Handle Remove Event
   */
  function handleRemoveEvent() {
    window.ElevarPushToDataLayer({
      event: 'dl_remove_from_cart',
      user_properties: userProperties,
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          actionField: {
            list: window.location.pathname,
          },
          products,
        },
      },
    });
  }

  return init();
}

export default elevarTracking;
